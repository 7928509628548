/* latin */
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(./assets/fonts/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

body,
* {
	margin: 0;
	font-family: "Poppins", sans-serif !important;
}

::-webkit-scrollbar {
	width: 3px;
	height: 3px;
}

::-webkit-scrollbar-track {
	background: #edf5fa;
}

::-webkit-scrollbar-thumb {
	border-radius: 2px;
	background: #005ea6;
}

.f-w-5 {
	font-weight: 500 !important;
}

.f-w-6 {
	font-weight: 600 !important;
}

.p-10 {
	padding: 10 !important;
}

.p-l-0 {
	padding-left: 0 !important;
}

.cursor-pointer {
	cursor: pointer;
}

.p-l-16 {
	padding-left: 16px;
}

.p-l-r-20 {
	padding: 0 20px 20px 20px;
}

.p-20 {
	padding: 20px;
}

.p-b-10 {
	padding-bottom: 10px;
}

.p-b-16 {
	padding-bottom: 16px;
}

.p-b-20 {
	padding-bottom: 20px;
}

.m-10 {
	margin: 10px !important;
}

.m-b-5 {
	margin-bottom: 5px !important;
}

.m-b-10 {
	margin-bottom: 10px !important;
}

.m-b-15 {
	margin-bottom: 15px !important;
}

.m-b-20 {
	margin-bottom: 20px !important;
}

.m-b-30 {
	margin-bottom: 30px !important;
}

.m-b-40 {
	margin-bottom: 40px !important;
}

.m-b-50 {
	margin-bottom: 50px !important;
}

.m-t-30 {
	margin-top: 30px !important;
}

.m-t-10 {
	margin-top: 10px !important;
}

.m-t-20 {
	margin-top: 20px !important;
}

.m-t-25 {
	margin-top: 25px !important;
}

.m-r-5 {
	margin-right: 5px !important;
}

.m-r-10 {
	margin-right: 10px !important;
}

.m-r-20 {
	margin-right: 20px !important;
}

.m-l-16 {
	margin-left: 16px !important;
}

.m-l-5 {
	margin-left: 5px !important;
}

.m-l-20 {
	margin-left: 20px !important;
}

.b-r-1 {
	border-right: 2px solid #b3bcc7;
}

.w-100 {
	width: 100%;
}

.MuiDataGrid-columnHeaders {
	background: #d3d3d3 !important;
}

.MuiDataGrid-cell:focus {
	outline: none !important;
}

.b-color {
	border: 1px solid #d3d3d3;
}

.MuiSelect-icon,
.MuiNativeSelect-icon {
	color: #b3bcc7 !important;
}

.highcharts-data-table table {
	font-family: Verdana, sans-serif;
	border-collapse: collapse;
	border: 1px solid #ebebeb;
	margin: 10px auto;
	text-align: center;
	width: 100%;
	max-width: 800px;
}

.highcharts-data-table caption {
	padding: 1em 0;
	font-size: 1.2em;
	color: #555;
}

.highcharts-data-table th {
	font-weight: 600;
	padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
	padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
	background: #f8f8f8;
}

.highcharts-data-table tr:hover {
	background: #f1f7ff;
}

.highcharts-container,
.highcharts-container svg {
	width: 100% !important;
}

.stats-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	height: 35px;
	width: 40px;
}

.stats-icon {
	height: 20px !important;
	width: 20px !important;
}

.black {
	-webkit-text-fill-color: #000 !important;
	color: #000;
}

.hide {
	display: none;
}

.disable {
	color: "#858C94 !important";
}

.jvectormap-zoomin {
	display: none;
}

.jvectormap-zoomout {
	display: none;
}

.PrivatePickersFadeTransitionGroup-root {
	color: #000 !important;
}

.PrivatePickersFadeTransitionGroup-root .MuiTypography-root {
	color: #000 !important;
}

.PrivatePickersFadeTransitionGroup-root .MuiPickersDay-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.38) !important;
}

.row1NetRevenue {
	display: none;
}

.row2NetRevenue {
	display: none;
}

.ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.macro-econ-table {
	max-width: calc(40vw - 70px);
}

.img-error-box {
	padding: 20px;
	border: 1px solid black;
	border-radius: 5px;
	font-size: 18px;
}

.text-muted {
	color: #a6a6a6;
}

.three-line-ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.premiumControl .css-yx81ki-MuiInputBase-root-MuiOutlinedInput-root {
	width: 200px;
}

.tooltip {
	box-shadow: 1px 1px;
	position: absolute;
	background: #fff;
	border-width: 1px;
	border-style: solid;
	padding: 10px;
}

.auto-complete .MuiAutocomplete-input {
	padding-top: 2px !important;
}

.mui-checkbox {
	color: #f50057 !important;
}

@media only screen and (min-width: 1612px) {
	.row1NetRevenue {
		display: block;
	}

	.row2NetRevenue {
		display: none;
	}
}

@media only screen and (min-width: 1240px) and (max-width: 1611px) {
	.row1NetRevenue {
		display: none;
	}

	.row2NetRevenue {
		display: block;
	}
}

@media only screen and (max-width: 1239px) {
	.row1NetRevenue {
		display: block;
	}

	.row2NetRevenue {
		display: none;
	}
}

@media only screen and (max-width: 599px) {
	.macro-econ-table {
		max-width: 300vw;
	}
}
